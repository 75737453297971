import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import Clipboard from "v-clipboard";

Vue.use(Buefy);
Vue.use(Clipboard);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    $route: {
      handler: (to, from) => {
        document.title =
          to.meta.title + " - sophieundchris.de" || "sophieundchris.de";
      },
      immediate: true
    }
  }
}).$mount("#app");
