




import { Component, Vue } from "vue-property-decorator";

@Component({
  data: () => {
    return {
      key: ""
    };
  }
})
export default class Home extends Vue {}
