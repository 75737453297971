






















import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Corona extends Vue {
  private stats: any = {
    guetersloh: {
      incidence: 0,
      name: "Fehler",
      updated: "never"
    },
    gummersbach: {
      incidence: 0,
      name: "Fehler",
      updated: "never"
    },
    koeln: {
      incidence: 0,
      name: "Fehler",
      updated: "never"
    }
  };

  private async getCoronaStatsFromApi(): Promise<void> {
    try {
      const response = await axios.get(
          "https://backend.chrizmas.de/stats"
      );

      const result = response.data;

      if (result.gm && result.gm.data) {
        this.stats.gummersbach.incidence = Math.round(
            result.gm.data["05374"].weekIncidence
        );
        this.stats.gummersbach.name = result.gm.data["05374"].name;
        this.stats.gummersbach.updated = result.gm.meta.lastUpdate;
      }

      if (result.k && result.k.data) {
        this.stats.koeln.incidence = Math.round(
            result.k.data["05315"].weekIncidence
        );
        this.stats.koeln.name = result.k.data["05315"].name;
        this.stats.koeln.updated = result.k.meta.lastUpdate;
      }

      if (result.gt && result.gt.data) {
        this.stats.guetersloh.incidence = Math.round(
            result.gt.data["05754"].weekIncidence
        );
        this.stats.guetersloh.name = result.gt.data["05754"].name;
        this.stats.guetersloh.updated = result.gt.meta.lastUpdate;
      }

      console.log(response.data);
      console.log(this.stats.gummersbach.incidence);
      console.log(this.stats.koeln.incidence);
      console.log(this.stats.guetersloh.incidence);
    } catch (e) {
      console.error(e);
    }
  }

  public async created(): Promise<void> {
    console.log("CREATED");
    await this.getCoronaStatsFromApi();
  }
}
