import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import NintendoFriendCode from "@/views/NintendoFriendCode.vue";
import Corona from "@/views/Corona.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/nintendo",
    name: "Nintendo Friends Code",
    component: NintendoFriendCode,
    meta: {
      title: "Nintendo Friends Code"
    }
  },
  {
    path: "/corona",
    name: "Corona Statistiken",
    component: Corona,
    meta: {
      title: "Corona Statistiken für Verl, Köln und Gummersbach"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
