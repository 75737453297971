

























































import { Component, Vue } from "vue-property-decorator";

@Component({
  data: () => {
    return {
      friendCode: "SW-3614-9404-7511",
      showSuccessNotification: false
    };
  },
  methods: {
    fadeInCloud() {
      (this as any).showSuccessNotification = true;

      setTimeout(() => {
        (this as any).showSuccessNotification = false;
      }, 3000);
    },
    fadeOutCloud() {
      (this as any).showSuccessNotification = false;
    }
  }
})
export default class NintendoFriendCode extends Vue {}
